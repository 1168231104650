<template>
    <investor-events-project-component/>
</template>

<script>
import InvestorEventsProjectComponent from "@/components/investors/InvestorEventsProjectComponent";
export default {
    name: "InvestorEventsProjectView",
    components: {InvestorEventsProjectComponent}
}
</script>

<style scoped>

</style>