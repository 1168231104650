export default {
    EventsChart: {
        series: [
            {
                name: 'Crecimiento',
                type: 'column',
                data: []
            },{
                name: 'Ingresos',
                type: 'line',
                data: []
            },{
                name: 'Utilidad',
                type: 'line',
                data: []
            }
        ],
        chartOptions: {
            chart: {
                id: 'chart-events',
                height: 400,
                width: '100%',
                type: 'line',
                stacked: false,
                toolbar: {
                    show: false,
                },
                zoom: {
                    enabled: false,
                }
            },
            stroke: {
                width: [1, 4, 4]
            },
            markers: {
                size: 5,
                strokeColors: "transparent",
            },
            grid: {
                borderColor: "rgba(0, 0, 0, .1)",
                show: true,
                enabled: true,
                xaxis: {
                    lines: {
                        show: false,
                    },
                },
                yaxis: {
                    lines: {
                        show: true,
                    },
                },
            },
            colors: [
                '#4989f6',
                //'#8000FF',
                '#079a67',
                '#88c75e'
            ],
            title: {
                text: '',
                align: 'center',
                style: {
                    color: '#444'
                }
            },
            tooltip: {
                enabled: true,
                custom: function({series, seriesIndex, dataPointIndex, w}) {
                    console.log('series:', series);
                    console.log('seriesIndex:', seriesIndex);
                    let data = w.globals.initialSeries[0].data[dataPointIndex];
                    return '<v-card elevation="2" outlined class="ma-3 caption transparent text-left justify-start">' +
                        '<table cellpadding="3" cellspacing="3" class="transparent">' +
                            '<tr class="text-left">' +
                                '<th>Evento: </th>' +
                                '<th>' +
                                    '<v-card-title class="grey--text text--darken-3">'+data.full_label+'</v-card-title>' +
                                '</th>' +
                            '</tr>' +
                            '<tr class="card-header" style="color: #4989f6;">' +
                                '<th>Crecimiento: </th>' +
                                '<td>' +
                                    '<v-card-title class="grey--text text--darken-3">'+
                                        '<span style="color: #4989f6;">' +
                                            data.y.toLocaleString("en-US",{style:"currency", currency:"USD"})+' mxn</v-card-title>' +
                                        '</span>'+
                                '</td>' +
                            '</tr>' +
                            '<tr class="card-header" style="color: #8000FF;">' +
                                '<th>Ingresos: </th>' +
                                '<td>' +
                                    '<v-card-title class="grey--text text--darken-3">'+
                                    '    <span style="color: #8000FF;">'+
                                            data.income.toLocaleString("en-US",{style:"currency", currency:"USD"})+' mxn'+
                                    '   </span> '+
                                    ' </v-card-title>' +
                                '</td>' +
                            '</tr>' +
                            '<tr class="card-header" style="color: #079a67;">' +
                                '<th>Utilidad: </th>' +
                                '<td>' +
                                    '<v-card-title class="grey--text text--darken-3">'+
                                        '<span style="color: #079a67;">' +
                                            data.utility.toLocaleString("en-US",{style:"currency", currency:"USD"})+' mxn' +
                                        '</span>'+
                                    '</v-card-title> ' +
                                '</td>' +
                            '</tr>' +
                        '</table>' +
                        '</v-card>';
                }
            },
            dataLabels: {
                enabled: false,
                enabledOnSeries: [1],
                formatter: function (value) {
                    return value.toLocaleString("en-US",{style:"currency", currency:"USD"});
                }
            },
            labels: ['Crecimiento', 'Ingresos', 'Utilidad/Pérdida'],
            xaxis: {
                type: 'string',
                lines: {
                    show: true,
                },
                categories: [],
                labels: {
                    style: {
                        cssClass: "grey--text text--lighten-2 fill-color",
                    }
                },
            },
            yaxis: [
                {
                    seriesName: "Crecimiento",
                    min: 0,
                    show: true,
                    type: "numeric",
                    lines: {
                        show: true,
                    },
                    axisTicks: {
                        show: true,
                    },
                    axisBorder: {
                        show: true,
                        color: '#dedee0'
                    },
                    labels: {
                        style: {
                            colors: '#4989f6',
                        },
                        formatter: function (value) {
                            return value.toLocaleString("en-US",{style:"currency", currency:"USD"});
                        }
                    },
                    title: {
                        text: 'Crecimiento',
                        style: {
                            color: '#4989f6',
                        }
                    },
                    tooltip: {
                        enabled: false
                    },
                },{
                    seriesName: "Ingresos",
                    min: 0,
                    show: true,
                    type: "numeric",
                    opposite: true,
                    lines: {
                        show: true,
                    },
                    axisTicks: {
                        show: true,
                    },
                    axisBorder: {
                        show: true,
                        color: '#079a67'
                    },
                    labels: {
                        show: true,
                        style: {
                            colors: '#079a67',
                        },
                        formatter: function (value) {
                            return value.toLocaleString("en-US",{style:"currency", currency:"USD"});
                        }
                    },
                    title: {
                        text: 'Utilidad o Pérdida',
                        style: {
                            color: '#05754e',
                        }
                    },
                },{
                    seriesName: "Ingresos",
                    min: 0,
                    type: "numeric",
                    show: false,
                    opposite: true,
                    axisTicks: {
                        show: true,
                    },
                    axisBorder: {
                        show: true,
                        color: '#00E396'
                    },
                    labels: {
                        show: true,
                        style: {
                            colors: '#05754e',
                        },
                        formatter: function (value) {
                            return value.toLocaleString("en-US",{style:"currency", currency:"USD"});
                        }
                    },
                    title: {
                        text: 'Utilidad/Pérdida',
                        style: {
                            color: '#05754e',
                        }
                    },
                }
            ]
        }
    }
};