<template>
    <v-container fill-height fluid text-xs-center>
        <v-card class="fill-height container--fluid" width="100vw" flat outlined>
            <v-data-table
                :headers="headers"
                :items="events"
                :items-per-page="10"
                :search="searchEvent"
                :loading="loadingData"
                :expanded="expanded"
                single-expand
                show-expand
                item-key="id"
                sort-by="id"
                disable-sort
                no-data-text="No hay datos para mostrar..."
                loading-text="Cargando los datos, por favor espere."
                class="elevation-1"
                :footer-props="{
                        showFirstLastPage: true,
                        disableItemsPerPage: false,
                        itemsPerPageAllText: 'Todas',
                        itemsPerPageText: 'Filas por página:'
                    }">
                <template v-slot:top>
                    <v-toolbar flat class="align-center">
                        <v-toolbar-title :class="$vuetify.breakpoint.smAndDown ? 'text-subtitle-1 text-md-h5' : ''">
                            <span>Eventos del Proyectos</span>
                        </v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                        <v-responsive max-width="300" height="45" class="hidden-sm-and-down">
                            <v-text-field
                                id="findtext-project"
                                label="Buscar..."
                                name="findproject"
                                outlined
                                dense
                                single-line
                                clearable
                                background-color="white"
                                hide-details
                                v-model="searchEvent"
                                class="white--text mt-0 pt-0 mr-1"
                                prepend-inner-icon="mdi-magnify"
                            ></v-text-field>
                        </v-responsive>
                        <v-btn @click="getEvents"
                               :loading="loadingData"
                               elevation="0"
                               :small="$vuetify.breakpoint.smAndDown"
                               class="mb-1 ml-2"
                        >
                          <v-icon>mdi-reload</v-icon>
                        </v-btn>
                        <v-dialog
                            :fullscreen="$vuetify.breakpoint.smAndDown"
                            v-model="dialogDocumentsPDF"
                            persistent
                            width="600">
                            <v-card>
                                <v-toolbar dark color="primary" height="50">
                                    <v-btn
                                        class="hidden-sm-and-down"
                                        icon
                                        dark
                                        @click="dialogDocumentsPDF = false">
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                    <v-toolbar-title>Documentos PDF</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <v-toolbar-items>
                                        <v-btn dark
                                               text
                                               @click="dialogDocumentsPDF = false">
                                            Cerrar
                                        </v-btn>
                                    </v-toolbar-items>
                                </v-toolbar>
                                <v-container fill-height bottom-gradient fluid class="ma-0">
                                    <v-row>
                                        <v-col>
                                            <div class="fill-height">
                                                <v-list dense subheader>
                                                    <v-subheader>Lista de Archivos</v-subheader>
                                                    <v-divider/>
                                                    <v-list-item
                                                        v-for="doc in event_documents"
                                                        :key="doc.id">
                                                        <v-list-item-icon>
                                                            <v-icon color="primary">
                                                                mdi-file-document
                                                            </v-icon>
                                                        </v-list-item-icon>
                                                        <v-list-item-content>
                                                            <v-list-item-title
                                                                v-text="doc.name"></v-list-item-title>
                                                        </v-list-item-content>
                                                        <v-list-item-icon>
                                                            <v-tooltip bottom v-if="doc.url != null">
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-btn
                                                                        dark
                                                                        icon
                                                                        small
                                                                        color="orange"
                                                                        class="ma-0 text--darken-1"
                                                                        elevation="0"
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                        v-on:click="openPDF(doc)">
                                                                        <v-icon>mdi-magnify</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>Ver documento</span>
                                                            </v-tooltip>
                                                        </v-list-item-icon>
                                                    </v-list-item>
                                                </v-list>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card>
                        </v-dialog>
                        <v-dialog
                            :fullscreen="$vuetify.breakpoint.smAndDown"
                            v-model="dialogChartEvent"
                            persistent
                            width="700">
                            <v-card>
                                <v-toolbar dark color="primary" height="60">
                                    <v-btn
                                        class="hidden-sm-and-down"
                                        icon
                                        dark
                                        @click="dialogChartEvent = false">
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                    <v-toolbar-title>Gr&aacute;fica del Evento</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <v-toolbar-items>
                                        <v-btn dark
                                               text
                                               @click="dialogChartEvent = false">
                                            Cerrar
                                        </v-btn>
                                    </v-toolbar-items>
                                </v-toolbar>
                                <v-container fill-height bottom-gradient fluid class="ma-0">
                                    <v-row>
                                        <v-col>
                                            <div class="fill-height">
                                                <div class="container--fluid fill-height" style="width:100%;">
                                                    <VueApexCharts
                                                        ref="chartEvents"
                                                        height="400" type="line"
                                                        :options="eventChartSettings.EventsChart.chartOptions"
                                                        :series="eventChartSettings.EventsChart.series"/>
                                                </div>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
                <template v-slot:item.active="{ item }">
                    <v-simple-checkbox
                        v-model="item.active"
                        disabled
                    ></v-simple-checkbox>
                </template>
                <template v-slot:item.image_url="{ item }">
                    <v-avatar color="#ded06f" size="40">
                        <v-img
                            :src="(item.image_url !== null || item.image_url !== '') ? item.image_url :
                            require('@/assets/images/no-image.jpg')">
                        </v-img>
                    </v-avatar>
                </template>
                <template v-slot:item.start_date="{ item }">
                    <span>{{ formatDate(item.start_date) }}</span>
                </template>
                <template v-slot:item.finish_date="{ item }">
                    <span>{{ formatDate(item.finish_date) }}</span>
                </template>
                <template #item.amount="{ item }">
                    <span class="text-caption">
                        {{ item.pivot.investment_amount | toCurrency }} mxn
                    </span>
                </template>
                <template #item.income="{ item }">
                    <span class="text-caption">
                        {{ item.pivot.investment_income | toCurrency }} mxn
                    </span>
                </template>
                <template #item.expense="{ item }">
                    <span class="text-caption">
                        {{ item.pivot.expense | toCurrency }} mxn
                    </span>
                </template>
                <template #item.revuene="{ item }">
                    <span class="text-caption">
                        {{ item.pivot.utility | toCurrency }} mxn
                    </span>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                v-bind="attrs"
                                v-on="on"
                                small
                                class="mr-2"
                                @click="loadListDocuments(item)">
                                mdi-file-document
                            </v-icon>
                        </template>
                        <span>Mostrar Documentos PDF</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                v-bind="attrs"
                                v-on="on"
                                color="black"
                                small
                                class="mr-2"
                                @click="showChartEvent(item)">
                                mdi-chart-areaspline
                            </v-icon>
                        </template>
                        <span>Gr&aacute;fica del Evento</span>
                    </v-tooltip>
                </template>
                <template v-slot:no-data>
                    <span class="text-h5">No hay registros en el listado</span>
                    <br/>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                        <v-container fluid>
                            <v-row justify="start" class="ma-1">
                                <h3>{{ item.name }}</h3>
                            </v-row>
                            <v-row class="ma-1">
                                <v-col cols="12" md="8">
                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start">
                                        <v-col cols="12" md="4">
                                            Descripci&oacute;n:
                                        </v-col>
                                        <v-col>
                                            {{ item.description }}
                                        </v-col>
                                    </v-row>
                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start">
                                        <v-col cols="12" md="4">
                                            Ubicaci&oacute;n:
                                        </v-col>
                                        <v-col>
                                            {{ item.location }}
                                        </v-col>
                                    </v-row>
                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start">
                                        <v-col cols="12" md="4">
                                            Fecha de inicio:
                                        </v-col>
                                        <v-col>
                                            {{ formatDate(item.start_date) }}
                                        </v-col>
                                    </v-row>
                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start" v-if="false">
                                        <v-col cols="12" md="4">
                                            Fecha estimada de terminación:
                                        </v-col>
                                        <v-col>
                                            {{ formatDate(item.finish_date) }}
                                        </v-col>
                                    </v-row>
                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start" v-if="true">
                                        <v-col cols="12" md="4">
                                            Monto Inversi&oacute;n:
                                        </v-col>
                                        <v-col>
                                            {{ item.pivot.investment_amount | toCurrency }} mxn
                                        </v-col>
                                    </v-row>
                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start" v-if="true">
                                        <v-col cols="12" md="4">
                                            Ingresos:
                                        </v-col>
                                        <v-col>
                                            {{ item.pivot.investment_income | toCurrency }} mxn
                                        </v-col>
                                    </v-row>
                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start" v-if="true">
                                        <v-col cols="12" md="4">
                                            Gasto de Inversi&oacute;n:
                                        </v-col>
                                        <v-col>
                                            {{ item.pivot.expense | toCurrency }} mxn
                                        </v-col>
                                    </v-row>
                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start" v-if="true">
                                        <v-col cols="12" md="4">
                                            Utilidad:
                                        </v-col>
                                        <v-col>
                                            {{ item.pivot.utility | toCurrency}} mxn
                                        </v-col>
                                    </v-row>
                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start" v-if="false">
                                        <v-col cols="12" md="4">
                                            Canal de Youtube:
                                        </v-col>
                                        <v-col>
                                            <span v-if="item.youtube_video !== null">
                                                {{ item.youtube_video }}
                                            </span>
                                            <span v-else>
                                                (ninguno)
                                            </span>
                                        </v-col>
                                    </v-row>
                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start" v-if="false">
                                        <v-col cols="12" md="4">
                                            Estado del Evento:
                                        </v-col>
                                        <v-col>
                                            <span v-if="item.status === 'started'">
                                                Iniciado
                                            </span>
                                            <span v-else-if="item.status === 'execution'">
                                                En avance
                                            </span>
                                            <span v-else>
                                                Terminado
                                            </span>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-row justify="center">
                                        <v-img
                                            :src="item.image_url != null ? item.image_url :
                                            require('@/assets/images/no-image.jpg')"
                                            :lazy-src="require('@/assets/images/no-image.jpg')"
                                            height="100%"
                                            width="100%"
                                            aspect-ratio="1"
                                            class="white--text align-center justify-center elevation-2">
                                        </v-img>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-container>
                    </td>
                </template>
            </v-data-table>
        </v-card>
    </v-container>
</template>

<script>
    import moment from "moment";
    import investorService from '@/providers/InvestorService';
    import VueApexCharts from "vue-apexcharts";
    import EventChartSettings from "./EventChartSettings";

    export default {
        name: "InvestorEventsProjectComponent",
        components: { VueApexCharts },
        data: () => ({
            dialogDocumentsPDF: false,
            dialogChartEvent: false,
            event_documents: [],
            searchEvent: "",
            loadingData: false,
            investor: null,
            event: {},
            events: [],
            expanded: [],
            selectedInvestorId: 0,
            eventChartSettings: EventChartSettings,
            headers: [
                {text: '', value: 'data-table-expand', width: 5},
                {text: '', value: 'image_url', width: 40},
                {text: "Id", value: "id", align: " d-none"}, // para ocultar la columna Id.
                {
                    text: "Nombre del Evento",
                    align: "start",
                    sortable: false,
                    value: "name"
                },
                {text: "Fecha de Inicio", value: "start_date", width: 150},
                {text: "Inversión", value: "amount"},
                {text: "Ingresos", value: "income"},
                {text: "Gastos", value: "expense"},
                {text: "Utilidad", value: "revuene"},
                {text: "Acciones", value: "actions", width: 80, align: "center", sortable: false}
            ],
        }),
        filters: {
            formatTheDate(value) {
                if (value) {
                    return moment(String(value)).format('DD/MM/yyyy');
                }
            },
            toCurrency (value) {
                if (typeof value !== "number") {
                    return value;
                }
                let formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    useGrouping: true,
                    minimumFractionDigits: 2
                });
                return formatter.format(value);
            },
            toTwoDecimal (value) {
                if (typeof value !== "number") {
                    return value;
                }
                let formatter = new Intl.NumberFormat('en-US', {
                    useGrouping: false,
                    maximumSignificantDigits: 2,
                    minimumFractionDigits: 2
                });
                return formatter.format(value);
            }
        },
        computed: {
            computedStartDateFormatted() {
                return this.editedItem.start_date ? this.formatDate(this.editedItem.start_date) : '';
            },
            computedFinishDateFormatted() {
                return this.editedItem.finish_date ? this.formatDate(this.editedItem.finish_date) : '';
            },
            containerCSS() {
                return {
                    width: "100%",
                    height: this.height,
                };
            }
        },
        methods: {
            formatDate(value) {
                if (!value) return null;
                let theDate = value.toString().substr(0, 10);
                const [year, month, day] = theDate.split('-')
                return `${day}/${month}/${year}`;
            },
            parseDate(date) {
                if (!date) return null;
                let theDate = date.toString().substr(0, 10);
                const [month, day, year] = theDate.split('/');
                return `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`;
            },
            getEvents() {
                this.loadingData = true;
                // Catalogo de Proyectos...
                // getListInvestorEvents
                investorService.getListInvestorEventsProject(this.selectedInvestorId).then(record => {
                    //this.investor = record.value;
                    //this.events = this.investor.events;
                    this.events = record.value;
                }).catch(err => {
                    console.log("Error: ", err);
                }).finally(() =>{ this.loadingData = false; });
            },
            /* Para administrar los documentos */
            loadListDocuments(item) {
                this.event_documents = Object.assign({}, item.events_documents);
                if (!this.dialogDocumentsPDF) this.dialogDocumentsPDF = true;
            },
            openPDF(document) {
                //  let baseURL = this.$store.getters.getBaseURL;
                let urlPdf = document.url; // `${baseURL}/view-pdf`;
                window.open(urlPdf, "_blank");
            },
            getDataEvents(investor_id, event_id) {
                // Eventos
                investorService.getReportDetailEventsByIdInvestor(investor_id, event_id).then(res => {
                    if (res.success) {
                        this.dataReportEventInvestor = res.value;
                        this.event = this.dataReportEventInvestor;
                        this.eventChartSettings.EventsChart.chartOptions.title.text = 'Evento';
                        this.eventChartSettings.EventsChart.series[0].data = [];
                        this.eventChartSettings.EventsChart.series[1].data = [];
                        this.eventChartSettings.EventsChart.chartOptions.labels = [];
                        let growValues = [];
                        let revueneValues = [];
                        let expenseValues = [];
                        let labels = [];
                        let growValue = {
                            x : this.event.event_name,                  // event_index_name,
                            y : this.event.investment_income_event,
                            full_label: this.event.event_name,
                            income: this.event.investment_income_event,
                            expense: this.event.expense_event,
                            utility: this.event.utility_event,
                        };
                        growValues.push(growValue);
                        let expenseValue = {
                            x : this.event.event_name,        // event_index_name,
                            y : this.event.expense_event,
                            full_label: this.event.event_name
                        }
                        expenseValues.push(expenseValue);
                        let revenueValue = {
                            x : this.event.event_name,        // event_index_name,
                            y : this.event.utility_event,
                            full_label: this.event.event_name
                        };
                        revueneValues.push(revenueValue);
                        labels.push(this.event.event_name);

                        this.eventChartSettings.EventsChart.series = [
                            {
                                name: 'Ingreso Capital',
                                type: 'column',
                                data: growValues
                            },{
                                name: 'Utilidad o Pérdida',
                                type: 'line',
                                data: revueneValues
                            }
                        ]
                        this.eventChartSettings.EventsChart.chartOptions.xaxis.categories = labels;
                        this.$refs.chartEvents.refresh();
                    } else {
                        this.dataReportEventInvestor = null;
                    }
                });
            },
            showChartEvent(item) {
                this.getDataEvents(this.selectedInvestorId, item.id);
                if (!this.dialogChartEvent) this.dialogChartEvent = true;
            }
        },
        mounted() {
            this.events = [];
            let sessionInvestor = Object.assign({}, JSON.parse(sessionStorage.getItem('investor')));
            if (sessionInvestor !== null) {
                this.selectedInvestorId = sessionInvestor.user.investors[0].id;
                this.getEvents();
            }
            setTimeout(() => (this.elementVisible = true), 10);
        }
    }
</script>

<style scoped>

</style>